<mat-toolbar class="navbar print-hide-header" *ngIf="visible_header">
  <div class="sidenav-toggle-btn sidebar-toggler onMob" (click)="toggleMinimize()" *ngIf="!AuthService.isAgent()">
    <a mat-icon-button><mat-icon>menu</mat-icon></a>
  </div>
  <div class="navbar-content">
    <div id="navbarMenu" class="nav justify-content-end">
      <a mat-icon-button [matMenuTriggerFor]="notification" aria-label="Example icon-button with a menu" *ngIf="notifications?.length > 0">
        <mat-icon>notifications</mat-icon>
          <span class="count">{{notifications?.length}}</span>
      </a>
      <mat-menu #notification="matMenu">
        <mat-list *ngFor="let notification of notifications; let i = index">
          <div mat-subheader>{{notification.type}}</div>
          <mat-list-item>
            <div mat-line>{{notification.message}}</div>
            <div mat-line class="tx-color-03"> {{notification.created_at | date: 'dd MMM, yyyy' }}
              <a mat-icon-button color="accent" (click)="viewNotification($event, notification.id, i)"><mat-icon>visibility</mat-icon></a>
              <a mat-icon-button color="primary" (click)="markAsReadNotification($event, notification.id, i)"><mat-icon>done_all</mat-icon></a>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
      </mat-menu>

      <mat-menu #beforeMenu="matMenu" class="accountMenu">
        <div class="d-flex flex-column align-items-center px-3">
          <div class="mb-3">
            <img src="{{(logged_in_user && logged_in_user.avatar)? endpoint.apiUrl+''+logged_in_user.avatar :'./assets/avatar/avatar.png'}}" alt="" class="wd-80 ht-80 rounded-circle">
          </div>
          <div class="text-center">
            <p class="tx-16 user tx-medium mg-b-0">{{(logged_in_user && logged_in_user.name) ? logged_in_user.name : 'User'}}</p>
            <p class="tx-12 email mg-b-0">{{(logged_in_user && logged_in_user.email) ? logged_in_user.email : ''}}</p>
          </div>
        </div>
        <!-- <a mat-menu-item class="user-name">
          <img src="{{(logged_in_user && logged_in_user.avatar)? endpoint.apiUrl+''+logged_in_user.avatar :'./assets/avatar/avatar.png'}}" class="rounded-list-circle">
        {{(logged_in_user && logged_in_user.name) ? logged_in_user.name : 'User'}}</a> -->
        <hr>
        <mat-list role="list" class="actions pd-t-0">
          <mat-list-item role="listitem">
            <a *ngIf="!AuthService.isAgent()" [routerLink]="['operation/user/manage-setting']" routerLinkActive="router-link-active"><mat-icon aria-hidden="false">manage_accounts</mat-icon>Manage Account</a>
          </mat-list-item>
          <mat-list-item role="listitem" *ngIf="AuthService.isAdmin() || AuthService.isSuperAdmin()">
            <a *ngIf="!AuthService.isAgent() && (AuthService.isAdmin() || AuthService.isSuperAdmin())" [routerLink]="['operation/requests']" routerLinkActive="router-link-active"><mat-icon aria-hidden="false">swap_horiz</mat-icon>Requests</a>
          </mat-list-item>
          <mat-list-item role="listitem" *ngIf="AuthService.isAdmin() || AuthService.isSuperAdmin()">
            <a *ngIf="!AuthService.isAgent()" [routerLink]="['attendance']" routerLinkActive="router-link-active"><mat-icon aria-hidden="false">event_available</mat-icon>Attendance</a>
          </mat-list-item>
          <mat-list-item role="listitem">
            <a (click)="logout()"><mat-icon color="warn">logout</mat-icon>Logout</a>
          </mat-list-item>
        </mat-list>
        <!-- <a class="py-2" mat-menu-item *ngIf="!AuthService.isAgent()" [routerLink]="['operation/user/manage-setting']" routerLinkActive="router-link-active"><mat-icon aria-hidden="false">manage_accounts</mat-icon>Manage Account</a>
        <a class="py-2" mat-menu-item *ngIf="!AuthService.isAgent()" [routerLink]="['operation/requests']" routerLinkActive="router-link-active"><mat-icon aria-hidden="false">swap_horiz</mat-icon>Requests</a>
        <a class="py-2" mat-menu-item *ngIf="!AuthService.isAgent()" [routerLink]="['attendance']" routerLinkActive="router-link-active"><mat-icon aria-hidden="false">event_available</mat-icon>Attendance</a>
        <a class="py-2" mat-menu-item (click)="logout()" class="pending tx-medium"><mat-icon color="warn">logout</mat-icon>Logout</a> -->
      </mat-menu>
      <ul class="nav navbar-menu mHide">
        <li class="nav-item with-sub" *ngIf="accessMenuModel.includes('Products')">
          <a mat-button [matMenuTriggerFor]="manage" #menuTrigger="matMenuTrigger">Products<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #manage="matMenu" [overlapTrigger]="false">
            <a mat-menu-item [matMenuTriggerFor]="tour_itinerary" *ngIf="permissions['Itinerary'] && permissions['Itinerary']?.length > 0"><mat-icon>transfer_within_a_station</mat-icon>Itinerary</a>
            <a mat-menu-item [matMenuTriggerFor]="hotel" *ngIf="permissions['Hotel'] && permissions['Hotel']?.length > 0"><mat-icon>hotel</mat-icon>Hotel</a>
            <a mat-menu-item [matMenuTriggerFor]="packageHotel" *ngIf="permissions['Package Hotel'] && permissions['Package Hotel']?.length > 0"><mat-icon>hotel</mat-icon>Package Hotel</a>
            <a mat-menu-item [matMenuTriggerFor]="CustomisedPackage" *ngIf="permissions['Customised Package'] && permissions['Customised Package']?.length > 0"><mat-icon>tour</mat-icon>Customised Package</a>
            <a mat-menu-item [matMenuTriggerFor]="ferry" *ngIf="permissions['Ferry'] && permissions['Ferry']?.length > 0"><mat-icon>directions_boat</mat-icon>Ferry</a>
            <a mat-menu-item [routerLink]="['/admin/place']" *ngIf="hasPermission('Place', 'Place', 'Read') == true"><mat-icon>location_on</mat-icon>Place</a>
            <a mat-menu-item [routerLink]="['/admin/markups']" *ngIf="hasPermission('Markup', 'Markup', 'Read') == true"><mat-icon>donut_small</mat-icon>Markup</a>
          </mat-menu>

          <mat-menu #tour_itinerary="matMenu">
            <a mat-menu-item [routerLink]="['/admin/tour-itinerary']" *ngIf="hasPermission('Itinerary', 'New Itinerary', 'Read') == true">New Itinerary</a>
            <a mat-menu-item [routerLink]="['/admin/tour-itinerary-list']" *ngIf="hasPermission('Itinerary', 'Itinerary List', 'Read') == true">Itinerary list</a>
          </mat-menu>

          <mat-menu #hotel="matMenu">
            <a mat-menu-item [routerLink]="['/admin/hotel']" *ngIf="hasPermission('Hotel', 'New Hotel', 'Read') == true">New hotel</a>
            <a mat-menu-item [routerLink]="['/admin/hotel-list']" *ngIf="hasPermission('Hotel', 'Hotel List', 'Read') == true">Hotel list</a>
          </mat-menu>

          <mat-menu #packageHotel="matMenu">
            <a mat-menu-item [routerLink]="['/admin/package-hotel']" *ngIf="hasPermission('Package Hotel', 'New Package Hotel', 'Read') == true">New package hotel</a>
            <a mat-menu-item [routerLink]="['/admin/package-hotel-list']" *ngIf="hasPermission('Package Hotel', 'Package Hotel List', 'Read') == true">Package hotel list</a>
          </mat-menu>

          <mat-menu #CustomisedPackage="matMenu">
            <a mat-menu-item *ngIf="hasPermission('Customised Package', 'Customised Package Form', 'Read') == true" [routerLink]="['/admin/customized-package-form']">Customised Package Form</a>
            <a mat-menu-item [routerLink]="['/files/file']" *ngIf="hasPermission('Customised Package', 'Customised Package', 'Read') == true">Customised Package</a>
          </mat-menu>

          <mat-menu #ferry="matMenu">
            <a mat-menu-item [routerLink]="['/admin/ferry-ticket-rate']" *ngIf="hasPermission('Ferry', 'New Ferry Rate', 'Read') == true">New Ferry rate</a>
            <a mat-menu-item [routerLink]="['/admin/ferry-ticket-rate-list']" *ngIf="hasPermission('Ferry', 'Ferry Rate List', 'Read') == true">Ferry rate list</a>
          </mat-menu>
        </li>

        <li class="nav-item with-sub" *ngIf="accessMenuModel.includes('Reports')">
          <a mat-button [matMenuTriggerFor]="report" #reportMenuTrigger="matMenuTrigger">Report<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #report="matMenu">
            <a mat-menu-item [routerLink]="['/admin/sales-report']" *ngIf="hasPermission('Sales', 'Sales', 'Read') == true"><mat-icon>sell</mat-icon>Sales</a>
            <a mat-menu-item [routerLink]="['/admin/hotel-purchase']" *ngIf="hasPermission('Hotel Purchase', 'Hotel Purchase', 'Read') == true"><mat-icon>hotel</mat-icon>Hotel Purchase</a>
            <a mat-menu-item [routerLink]="['/admin/vehicle-report']" *ngIf="hasPermission('Vehicle', 'Vehicle', 'Read') == true"><mat-icon>commute</mat-icon>Vehicle</a>
            <a mat-menu-item [routerLink]="['/admin/ticket-report']" *ngIf="hasPermission('Ticket', 'Ticket', 'Read') == true"><mat-icon>local_activity</mat-icon>Ticket</a>
            <a mat-menu-item [routerLink]="['/admin/guest-record']"><mat-icon>local_activity</mat-icon>Guest Record</a>
            <!-- <a mat-menu-item [routerLink]="['/admin/sales-report']"><mat-icon>sell</mat-icon>Addon Sales</a> -->
            <a mat-menu-item [routerLink]="['/admin/agency-enquiry-status']" *ngIf="hasPermission('Agency Report', 'Agency Report', 'Read') == true"><mat-icon>person</mat-icon>Agency report</a>
            <a mat-menu-item [routerLink]="['/accounts/hotel-sales-report']" *ngIf="hasPermission('Hotel Payment Report', 'Hotel Payment Report', 'Read') == true"><mat-icon>summarize</mat-icon>Hotel Payment Report</a>
          </mat-menu>
        </li>

        <li class="nav-item with-sub" *ngIf="accessMenuModel.includes('Accounts')">
          <a mat-button [matMenuTriggerFor]="account" #accountMenuTrigger="matMenuTrigger" (click)="getDepositPendingVerificationCount()">Accounts<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #account="matMenu">
            <a mat-menu-item [routerLink]="['/accounts/deposit-payment-confirmation']" *ngIf="hasPermission('Deposit verification', 'Deposit verification', 'Read') == true"><mat-icon>money</mat-icon>Deposit verification<span class="pending"> ({{count}})</span></a>
            <a mat-menu-item [routerLink]="['/accounts/credit-bank-statement']" *ngIf="hasPermission('Credited Bank Statement', 'Credited Bank Statement', 'Read') == true"><mat-icon>list</mat-icon>Credited bank statement</a>
            <a mat-menu-item [matMenuTriggerFor]="user" *ngIf="permissions['HR'] && permissions['HR']?.length > 0"><mat-icon>supervised_user_circle</mat-icon>HR</a>
            <a mat-menu-item [routerLink]="['/accounts/agency']" *ngIf="hasPermission('Add Agency', 'Add Agency', 'Write') == true"><mat-icon>person</mat-icon>Add Agency</a>
            <a mat-menu-item [routerLink]="['accounts/credit-note-list']" *ngIf="hasPermission('Credit Note', 'Credit Note', 'Read') == true"><mat-icon>descriptions</mat-icon>Credit note</a>
            <a mat-menu-item [matMenuTriggerFor]="invoice" *ngIf="permissions['Invoice'] && permissions['Invoice']?.length > 0"><mat-icon>receipt_long</mat-icon>Invoice</a>
            <a mat-menu-item [routerLink]="['accounts/gst']" *ngIf="hasPermission('GST', 'GST', 'Read') == true"><mat-icon>percent</mat-icon>GST's</a>
            <a mat-menu-item [routerLink]="['accounts/confirmation-list']" *ngIf="hasPermission('Confirmed Liability', 'Confirmed Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Confirmed Liability</a>
            <a mat-menu-item [matMenuTriggerFor]="vendors"  *ngIf="permissions['Vendor Registration'] && permissions['Vendor Registration']?.length > 0"><mat-icon>list</mat-icon>Vendor Registration</a>

            <mat-menu #user="matMenu">
              <a mat-menu-item [routerLink]="['/accounts/manage/user-list']" *ngIf="hasPermission('HR', 'Employee List', 'Read') == true">Employee list</a>
              <a mat-menu-item [routerLink]="['accounts/employee-payment']" *ngIf="hasPermission('HR', 'Employee Salary', 'Read') == true" routerLinkActive="router-link-active">Employee salary</a>
            </mat-menu>

            <mat-menu #invoice="matMenu">
              <a mat-menu-item [routerLink]="['accounts/invoice-list']" *ngIf="hasPermission('Invoice', 'Invoice', 'Read') == true" routerLinkActive="router-link-active">Invoice</a>
              <a mat-menu-item [routerLink]="['accounts/invoice-requests']" *ngIf="hasPermission('Invoice', 'Invoice Request', 'Read') == true" routerLinkActive="router-link-active">Invoice Request</a>
            </mat-menu>

            <mat-menu #vendors="matMenu">
              <a mat-menu-item [routerLink]="['accounts/vendors-list']" *ngIf="hasPermission('Vendor Registration', 'Vendor List', 'Read') == true" routerLinkActive="router-link-active">Vendors List</a>
              <a mat-menu-item [routerLink]="['accounts/vendor-registration']" *ngIf="hasPermission('Vendor Registration', 'Add Vendor', 'Read') == true" routerLinkActive="router-link-active">Add Vendor</a>
            </mat-menu>
          </mat-menu>
        </li>

        <li class="nav-item with-sub" *ngIf="accessMenuModel.includes('Liability')">
          <a mat-button [matMenuTriggerFor]="liability" #liabilityMenuTrigger="matMenuTrigger" (click)="getDepositPendingVerificationCount()">Liability<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #liability="matMenu">
            <a mat-menu-item [routerLink]="['/admin/liability-payment']" *ngIf="hasPermission('Hotel Liability', 'Hotel Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Hotel liability</a>
            <a mat-menu-item [routerLink]="['/admin/additional-liability-payment']" *ngIf="hasPermission('Additional Hotel Liability', 'Additional Hotel Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Additional Hotel liability</a>
            <a mat-menu-item [routerLink]="['/admin/vehicle-liability-payment']" *ngIf="hasPermission('Vehicle & Ticket Liability', 'Vehicle & Ticket Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Vehicle liability</a>
            <a mat-menu-item [routerLink]="['/admin/ticket-liability-payment']" *ngIf="hasPermission('Vehicle & Ticket Liability', 'Vehicle & Ticket Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Ticket liability</a>
            <a mat-menu-item [routerLink]="['/admin/addon-liability-payment']" *ngIf="hasPermission('Vehicle & Ticket Liability', 'Vehicle & Ticket Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Addon Service</a>
            <a mat-menu-item [routerLink]="['/admin/other-liability']"><mat-icon>money_off</mat-icon>Addon Liabilities</a>
            <a mat-menu-item [routerLink]="['/admin/other-liability']" *ngIf="hasPermission('Other Liability', 'Other Liability', 'Read') == true"><mat-icon>money_off</mat-icon>Other liability</a>
          </mat-menu>
        </li>

        <li class="nav-item with-sub" *ngIf="accessMenuModel.includes('Operation')">
          <a mat-button [matMenuTriggerFor]="operation" #operationMenuTrigger="matMenuTrigger">Operation<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #operation="matMenu" [overlapTrigger]="false">
            <a mat-menu-item [matMenuTriggerFor]="enquiry" *ngIf="permissions['Enquiry'] && permissions['Enquiry']?.length > 0"><mat-icon>help_center</mat-icon>Enquiry</a>
            <a mat-menu-item [matMenuTriggerFor]="confirmation" *ngIf="permissions['Confirmation'] && permissions['Confirmation']?.length > 0"><mat-icon>check_circle</mat-icon>Confirmation</a>
            <a mat-menu-item [matMenuTriggerFor]="operation_report" *ngIf="permissions['Report'] && permissions['Report']?.length > 0"><mat-icon>reports</mat-icon>Report</a>
            <a mat-menu-item [matMenuTriggerFor]="operation_liability" *ngIf="permissions['Liability'] && permissions['Liability']?.length > 0"><mat-icon>money_off</mat-icon>Liability</a>
            <a mat-menu-item [routerLink]="['/operation/hotel/inventory']" *ngIf="hasPermission('Hotel Inventory', 'Hotel Inventory', 'Read') == true"><mat-icon>inventory</mat-icon>Hotel Inventory</a>
          </mat-menu>

          <mat-menu #confirmation="matMenu">
            <a mat-menu-item [routerLink]="['/operation/confirmation-list']" *ngIf="hasPermission('Confirmation', 'Confirmation List', 'Read') == true">Confirmation list</a>
            <a mat-menu-item [routerLink]="['/operation/cancelled-confirmation']" *ngIf="hasPermission('Confirmation', 'Cancelled Confirmation', 'Read') == true">Cancelled confirmation</a>
          </mat-menu>
          <mat-menu #enquiry="matMenu">
            <a mat-menu-item [routerLink]="['/operation/enquiry']" *ngIf="hasPermission('Enquiry', 'New Enquiry', 'Read') == true">New Enquiry</a>
            <a mat-menu-item [routerLink]="['/operation/enquiry-list']" *ngIf="hasPermission('Enquiry', 'Enquiry List', 'Read') == true">Enquiry list</a>
          </mat-menu>
          <mat-menu #agency="matMenu">
            <a mat-menu-item [routerLink]="['/operation/agency']">New Agency</a>
            <a mat-menu-item [routerLink]="['/operation/agency-list']">Agency list</a>
          </mat-menu>
          <mat-menu #operation_report="matMenu">
            <a mat-menu-item *ngIf="hasPermission('Report', 'Sales Report', 'Read') == true" [routerLink]="['/operation/sales-report']">Sales report</a>
            <a mat-menu-item *ngIf="hasPermission('Report', 'Deposits', 'Read') == true" [routerLink]="['/operation/deposits']">Deposits</a>
            <a mat-menu-item *ngIf="hasPermission('Report', 'Statement', 'Read') == true" [routerLink]="['/operation/statements']">Statement</a>
            <a mat-menu-item *ngIf="hasPermission('Report', 'Invoices', 'Read') == true" [routerLink]="['/operation/invoice-list']">Invoices</a>
            <a mat-menu-item *ngIf="hasPermission('Report', 'Confirmation Report', 'Read') == true" [routerLink]="['/operation/confirmation-report']">Confirmation Report</a>
          </mat-menu>

          <mat-menu #operation_liability="matMenu">
            <a mat-menu-item *ngIf="hasPermission('Liability', 'Other Liability', 'Read') == true" [routerLink]="['/operation/hotel-liability']">Hotel</a>
          </mat-menu>
        </li>

        <li class="nav-item with-sub" *ngIf="accessMenuModel.includes('Ground Staff')">
          <a mat-button [matMenuTriggerFor]="manageGroung" #manageGroungMenuTrigger="matMenuTrigger">Ground staff<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #manageGroung="matMenu" [overlapTrigger]="false">
            <a mat-menu-item *ngIf="hasPermission('Coordinator Confirmation List', 'Coordinator Confirmation List', 'Read') == true" [routerLink]="['/admin/manage/coordinator/confirmation-list']">Coordinator confirmation list</a>
            <a mat-menu-item *ngIf="hasPermission('Confirmation List', 'Confirmation List', 'Read') == true" [routerLink]="['/admin/manage/confirmation-list']">Confirmation list</a>
            <a mat-menu-item *ngIf="hasPermission('Driver Tour List', 'Driver Tour List', 'Read') == true" [routerLink]="['/admin/manage/driver-tour-list']">Driver tour list</a>
            <a mat-menu-item *ngIf="hasPermission('Logistic Management', 'Logistic Management', 'Read') == true" [routerLink]="['/admin/vehicles/inventory']">Logistic Management</a>
          </mat-menu>
        </li>


        <li class="nav-item with-sub" *ngIf="AuthService.isCoordinator()">
          <a mat-button [matMenuTriggerFor]="coordinator" #coordinatorMenuTrigger="matMenuTrigger">Allocation<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #coordinator="matMenu" [overlapTrigger]="false">
            <a mat-button [routerLink]="['/coordinator/coordinator-confirmation']">Coordinator confirmation list</a>
            <a mat-button [routerLink]="['/coordinator/drivers/tour-allocation']">Driver tour list</a>
          </mat-menu>
        </li>

        <li class="nav-item with-sub" *ngIf="AuthService.isDriver()">
          <a mat-button [routerLink]="['/drivers/tour-allocation']">Driver tour list</a>
        </li>

        <li class="nav-item with-sub" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
          <a mat-button [matMenuTriggerFor]="manageManager" #manageManagerMenuTrigger="matMenuTrigger">Ground Manager<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #manageManager="matMenu" [overlapTrigger]="false">
            <a mat-menu-item [routerLink]="['/ground-manager/confirmation-list']">Confirmation list</a>
            <a mat-menu-item [routerLink]="['/ground-manager/ground-payments']">Ground Payment</a>
            <a mat-menu-item [routerLink]="['/ground-manager/coordiator/confirmation-list']">Coordiator confirmation list</a>
            <a mat-menu-item [routerLink]="['/ground-manager/drivers/tour-allocation']">Driver tour list</a>
            <a mat-menu-item [matMenuTriggerFor]="vendors" *ngIf="AuthService.isGroundManager()">Vendor Registration</a>
            <mat-menu #vendors="matMenu">
              <a mat-menu-item [routerLink]="['/accounts/vendors-list']" routerLinkActive="router-link-active">Vendors List</a>
              <a mat-menu-item [routerLink]="['/accounts/vendor-registration']" routerLinkActive="router-link-active">Add Vendor</a>
            </mat-menu>
          </mat-menu>
        </li>

        <!-- <li class="nav-item with-sub" *ngIf="AuthService.isSuperAdmin()" matTooltip="Auto correct calculations">
          <a mat-icon-button (click)="updateFinalCalculate()" *ngIf="!calculate_loader"><mat-icon>calculate</mat-icon></a>
          <a mat-stroked-button *ngIf="calculate_loader"><mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>Calculating...</a>
          <mat-progress-bar *ngIf="calculate_loader" mode="indeterminate"></mat-progress-bar>
        </li> -->
      </ul>
      <div class="dropdown dropdown-profile">
          <div class="avatar avatar-sm">
            <div class="avatar avatar-sm" [matMenuTriggerFor]="beforeMenu"><img src="{{(logged_in_user && logged_in_user.avatar)? endpoint.apiUrl+''+logged_in_user.avatar :'./assets/avatar/avatar.png'}}" class="rounded-circle"></div>
          </div>
      </div>
      <!-- <div class="navbar-menu-header">
        <a href="/" class="df-logo">Trip<span>zolo</span></a>
        <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
      </div> -->
    </div>
  </div>
</mat-toolbar>
