<div class="content content-fixed">
  <div class="flex-center-between upper-section">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-style1 mg-b-0">
          <li class="breadcrumb-item"><a [routerLink]="['/ground-manager/dashboard']">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">All Confirmations</li>
        </ol>
      </nav> 
      <h4 class="main-heading mg-b-0">Confirmation List</h4>
    </div>
    <button mat-icon-button color="primary" (click)="toggleFilter()">
      <mat-icon>tune</mat-icon>
    </button>
    <div class="row actions-container filter-tools d-flex align-items-start" *ngIf="filterSection">
      <div class="col-md-3 col-12">
      </div>
      <div class="col-md-2 col-4">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label><mat-icon>tour</mat-icon> Tour</mat-label>
          <mat-select [(ngModel)]="tour.tour_status" name="tour_status" #tour_status="ngModel" (selectionChange)="tourStatusOnChange(true)">
            <mat-option value="complete_tour">Complete tours</mat-option>
            <mat-option value="upcoming_tour">Upcoming</mat-option>
            <mat-option value="on_tour">On tours</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 col-4" *ngIf="filter_date">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label><mat-icon>filter_alt</mat-icon> Month</mat-label>
          <mat-select [(ngModel)]="tour.month" name="month" #month="ngModel" (selectionChange)="tourStatusOnChange()">
            <mat-option value="0">None</mat-option>
            <mat-option *ngFor="let month of filter_date.month_value; let i = index" [value]="filter_date.months[i]">{{month}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 col-4" *ngIf="filter_date">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label><mat-icon>filter_alt</mat-icon> Year</mat-label>
          <mat-select [(ngModel)]="tour.year" name="year" #year="ngModel" (selectionChange)="tourStatusOnChange()">
            <mat-option value="0">None</mat-option>
            <mat-option *ngFor="let year of filter_date.years; let i = index" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 col-12">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Search confirmation</mat-label>
          <input matInput type="text" autocomplete="off" name="search_term" #search_term="ngModel"
            [(ngModel)]="search.search_term" (keyup)="onSearch($event)" (paste)="onSearch($event)">
          <button mat-button mat-icon-button *ngIf="search_spinner" matSuffix><mat-icon><mat-spinner diameter="15"></mat-spinner></mat-icon></button>
          <button mat-button mat-icon-button matPrefix><mat-icon>search</mat-icon></button>
          <button mat-button mat-icon-button *ngIf="search.search_term && !search_spinner" (click)="clearSearch()" matSuffix
            aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint>Min 3 Characters</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-1 col-xl-1 text-right">
        <button mat-icon-button (click)="closeFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- <mat-divider class="title-divider"></mat-divider> -->
  <div class="pd-x-0 pd-lg-x-10 pd-xl-x-0 my-3">
    <!-- <mat-tab-group mat-align-tabs="start" class="b_types"> -->
      <!-- <mat-tab label="All"> -->
        <div class="card mg-b-10">
          <div class="table-responsive">
            <!-- <button type="button" class="btn btn-xs btn-outline-dark" (click)="exportToExcel()">EXCEL</button> -->
            <table class="table table-dashboard mg-b-0">
              <thead>
                <tr class="enq-head">
                  <th>Confirmed Date</th>
                  <th>Enquiry Code</th>
                  <th>Type</th>
                  <th>Guest Name</th>
                  <th>Travel From</th>
                  <th>Travel to</th>
                  <th>Handled by</th>
                  <th>Confirmation</th>
                  <th>Service Voucher</th>
                  <th>Expenditure</th>
                  <th>Coordinator</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td colspan="12" *ngIf="confirmation_loader"><button mat-button><mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>Loading...</button></td>
                <td colspan="12" *ngIf="!confirmation_loader && confirmations.length == 0">Confirmation not found...</td>
              </tr>
                <tr *ngFor="let confirmation of confirmations; let i = index">
                  <td>{{confirmation.confirmed_package_costs && confirmation.confirmed_package_costs.created_at | date:"dd MMM, yyyy h:mm a"}}</td>
                  <td>{{confirmation.enquiry_code}}</td>
                  <td>{{confirmation.tour_type}}</td>
                  <td>{{confirmation.travellers.name | titlecase}}</td>
                  <td>{{confirmation.travellers.travel_date | date:"dd MMM, yyyy"}} </td>
                  <td>{{confirmation.travellers.travel_date_to | date:"dd MMM, yyyy"}}</td>
                  <td>{{confirmation.assigned_to.name}}</td>
                  <td><a mat-button target="_blank()" href="/ground-manager/confirmation-view/{{confirmation.enquiry_code}}"><mat-icon>visibility</mat-icon></a></td>
                  <td><a mat-button target="_blank()" href="/ground-manager/service-voucher/{{confirmation.enquiry_code}}"><mat-icon>note</mat-icon></a></td>
                  <td><a mat-button target="_blank()" href="/ground-manager/file-expenditure/{{confirmation.enquiry_code}}"><mat-icon>note</mat-icon></a></td>
                  <td>
                    <a mat-button [matMenuTriggerFor]="menu" (click)="getEnquiryIndex(i)" *ngIf="!updateBtn[i]"><mat-icon>person_add</mat-icon> {{confirmation.coordinator && confirmation.coordinator.name}}</a>
                    <mat-icon *ngIf="updateBtn[i]"><mat-spinner diameter="20" color="warn"></mat-spinner></mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>

            <mat-menu #menu="matMenu">
              <a mat-menu-item *ngFor="let coordinator of coordinators" (click)="onAssignCoordinator(coordinator.id, coordinator.name)">{{coordinator.name}}</a>
            </mat-menu>

            <mat-paginator #paginator *ngIf="pagination && pagination['paging']" [length]="pagination['paging'].total_count"
              showFirstLastButtons
                [previousPageIndex]="1"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 50, 75, 100]"
                (page)="pageEvent = getfilterData($event)"
              >
            </mat-paginator>
          </div>
        </div>
      <!-- </mat-tab> -->
      <!-- <mat-tab label="Upcoming Tours">
        <div class="card mg-b-10">
          <div class="table-responsive">
            <table class="table table-dashboard mg-b-0">
              <thead>
                <tr class="enq-head">
                  <th>Confirmed Date</th>
                  <th>Enquiry Code</th>
                  <th>Type</th>
                  <th>Guest Name</th>
                  <th>Travel From</th>
                  <th>Travel to</th>
                  <th>Handled by</th>
                  <th>Confirmation</th>
                  <th>Service Voucher</th>
                  <th>Coordinator</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td colspan="12" *ngIf="confirmation_loader"><button mat-button><mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>Loading...</button></td>
                <td colspan="12" *ngIf="!confirmation_loader && confirmations.length == 0">Confirmation not found...</td>
              </tr>
                <tr *ngFor="let confirmation of confirmations; let i = index">
                  <td>{{confirmation.confirmed_package_costs && confirmation.confirmed_package_costs.created_at | date:"dd MMM, yyyy h:mm a"}}</td>
                  <td>{{confirmation.enquiry_code}}</td>
                  <td>{{confirmation.tour_type}}</td>
                  <td>{{confirmation.travellers.name | titlecase}}</td>
                  <td>{{confirmation.travellers.travel_date | date:"dd MMM, yyyy"}} </td>
                  <td>{{confirmation.travellers.travel_date_to | date:"dd MMM, yyyy"}}</td>
                  <td>{{confirmation.assigned_to.name}}</td>
                  <td><a mat-button target="_blank()" href="/ground-manager/confirmation-view/{{confirmation.enquiry_code}}"><mat-icon>visibility</mat-icon></a></td>
                  <td><a mat-button target="_blank()" href="/ground-manager/service-voucher/{{confirmation.enquiry_code}}"><mat-icon>note</mat-icon></a></td>
                  <td>
                    <a mat-button [matMenuTriggerFor]="menu" (click)="getEnquiryIndex(i)" *ngIf="!updateBtn[i]"><mat-icon>person_add</mat-icon> {{confirmation.coordinator && confirmation.coordinator.name}}</a>
                    <mat-icon *ngIf="updateBtn[i]"><mat-spinner diameter="20" color="warn"></mat-spinner></mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>

            <mat-menu #menu="matMenu">
              <a mat-menu-item *ngFor="let coordinator of coordinators" (click)="onAssignCoordinator(coordinator.id, coordinator.name)">{{coordinator.name}}</a>
            </mat-menu>

            <mat-paginator #paginator *ngIf="pagination && pagination['paging']" [length]="pagination['paging'].total_count"
              showFirstLastButtons
                [previousPageIndex]="1"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 50, 75, 100]"
                (page)="pageEvent = getfilterData($event)"
              >
            </mat-paginator>
          </div>
        </div>
      </mat-tab> -->
      <!-- <mat-tab label="On Tour">
        <div class="card mg-b-10">
          <div class="table-responsive">
            <table class="table table-dashboard mg-b-0">
              <thead>
                <tr class="enq-head">
                  <th>Confirmed Date</th>
                  <th>Enquiry Code</th>
                  <th>Type</th>
                  <th>Guest Name</th>
                  <th>Travel From</th>
                  <th>Travel to</th>
                  <th>Handled by</th>
                  <th>Confirmation</th>
                  <th>Service Voucher</th>
                  <th>Coordinator</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td colspan="12" *ngIf="confirmation_loader"><button mat-button><mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>Loading...</button></td>
                <td colspan="12" *ngIf="!confirmation_loader && confirmations.length == 0">Confirmation not found...</td>
              </tr>
                <tr *ngFor="let confirmation of confirmations; let i = index">
                  <td>{{confirmation.confirmed_package_costs && confirmation.confirmed_package_costs.created_at | date:"dd MMM, yyyy h:mm a"}}</td>
                  <td>{{confirmation.enquiry_code}}</td>
                  <td>{{confirmation.tour_type}}</td>
                  <td>{{confirmation.travellers.name | titlecase}}</td>
                  <td>{{confirmation.travellers.travel_date | date:"dd MMM, yyyy"}} </td>
                  <td>{{confirmation.travellers.travel_date_to | date:"dd MMM, yyyy"}}</td>
                  <td>{{confirmation.assigned_to.name}}</td>
                  <td><a mat-button target="_blank()" href="/ground-manager/confirmation-view/{{confirmation.enquiry_code}}"><mat-icon>visibility</mat-icon></a></td>
                  <td><a mat-button target="_blank()" href="/ground-manager/service-voucher/{{confirmation.enquiry_code}}"><mat-icon>note</mat-icon></a></td>
                  <td>
                    <a mat-button [matMenuTriggerFor]="menu" (click)="getEnquiryIndex(i)" *ngIf="!updateBtn[i]"><mat-icon>person_add</mat-icon> {{confirmation.coordinator && confirmation.coordinator.name}}</a>
                    <mat-icon *ngIf="updateBtn[i]"><mat-spinner diameter="20" color="warn"></mat-spinner></mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>

            <mat-menu #menu="matMenu">
              <a mat-menu-item *ngFor="let coordinator of coordinators" (click)="onAssignCoordinator(coordinator.id, coordinator.name)">{{coordinator.name}}</a>
            </mat-menu>

            <mat-paginator #paginator *ngIf="pagination && pagination['paging']" [length]="pagination['paging'].total_count"
              showFirstLastButtons
                [previousPageIndex]="1"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 50, 75, 100]"
                (page)="pageEvent = getfilterData($event)"
              >
            </mat-paginator>
          </div>
        </div>
      </mat-tab> -->
      <!-- <mat-tab label="Completed Tours">
        <div class="card mg-b-10">
          <div class="table-responsive">
            <table class="table table-dashboard mg-b-0">
              <thead>
                <tr class="enq-head">
                  <th>Confirmed Date</th>
                  <th>Enquiry Code</th>
                  <th>Type</th>
                  <th>Guest Name</th>
                  <th>Travel From</th>
                  <th>Travel to</th>
                  <th>Handled by</th>
                  <th>Confirmation</th>
                  <th>Service Voucher</th>
                  <th>Coordinator</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td colspan="12" *ngIf="confirmation_loader"><button mat-button><mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>Loading...</button></td>
                <td colspan="12" *ngIf="!confirmation_loader && confirmations.length == 0">Confirmation not found...</td>
              </tr>
                <tr *ngFor="let confirmation of confirmations; let i = index">
                  <td>{{confirmation.confirmed_package_costs && confirmation.confirmed_package_costs.created_at | date:"dd MMM, yyyy h:mm a"}}</td>
                  <td>{{confirmation.enquiry_code}}</td>
                  <td>{{confirmation.tour_type}}</td>
                  <td>{{confirmation.travellers.name | titlecase}}</td>
                  <td>{{confirmation.travellers.travel_date | date:"dd MMM, yyyy"}} </td>
                  <td>{{confirmation.travellers.travel_date_to | date:"dd MMM, yyyy"}}</td>
                  <td>{{confirmation.assigned_to.name}}</td>
                  <td><a mat-button target="_blank()" href="/ground-manager/confirmation-view/{{confirmation.enquiry_code}}"><mat-icon>visibility</mat-icon></a></td>
                  <td><a mat-button target="_blank()" href="/ground-manager/service-voucher/{{confirmation.enquiry_code}}"><mat-icon>note</mat-icon></a></td>
                  <td>
                    <a mat-button [matMenuTriggerFor]="menu" (click)="getEnquiryIndex(i)" *ngIf="!updateBtn[i]"><mat-icon>person_add</mat-icon> {{confirmation.coordinator && confirmation.coordinator.name}}</a>
                    <mat-icon *ngIf="updateBtn[i]"><mat-spinner diameter="20" color="warn"></mat-spinner></mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>

            <mat-menu #menu="matMenu">
              <a mat-menu-item *ngFor="let coordinator of coordinators" (click)="onAssignCoordinator(coordinator.id, coordinator.name)">{{coordinator.name}}</a>
            </mat-menu>

            <mat-paginator #paginator *ngIf="pagination && pagination['paging']" [length]="pagination['paging'].total_count"
              showFirstLastButtons
                [previousPageIndex]="1"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 50, 75, 100]"
                (page)="pageEvent = getfilterData($event)"
              >
            </mat-paginator>
          </div>
        </div>
      </mat-tab> -->
    <!-- </mat-tab-group> -->
  </div>
</div>
